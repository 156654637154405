<template>
  <div>
    <vs-card>
      <div slot="header">
        <h3 class="font-medium py-4 px-10 bg-grey-background">Template Details</h3>
      </div>
      <div class="px-10 pt-6 pb-6">
      <vs-row class="mb-8" vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-w="4">
          <vs-row><span class="heading">Template Type</span></vs-row>
          <vs-row><span class="answer">{{ pages.templateCategory }}</span></vs-row>
        </vs-col>
        <vs-col vs-w="4">
          <vs-row><span class="heading">Product Name</span></vs-row>
          <vs-row><span class="answer">{{ pages.productName }}</span></vs-row>
        </vs-col>
        <vs-col vs-w="4">
          <vs-row><span class="heading">Product Type</span></vs-row>
          <vs-row><span class="answer">{{ pages.productType }}</span></vs-row>
        </vs-col>
      </vs-row>

      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full mb-6">
            <span class="heading" for="Description">Description</span>
            <vs-textarea counter="150" maxlength="150" name="bodyText" :counter-danger.sync="counterDanger" v-model="pages.description" id="product-description" class="product-description h-auto" @input="auto_grow('product-description')"></vs-textarea>
          </div>
        </vs-col>
        <vs-col vs-w="4">
          <div class="w-full mb-1">
            <span class="heading">Sender email address <span class="mid-blue">*</span></span>
            <vs-input class="w-full" v-validate="'required|emailFormat'" size="large" v-model="pages.senderEmail" name="senderEmail" id="senderEmail" v-if="pages.productName == 'Default' || !pages.productName"/>
            <span class="text-danger text-sm" v-show="errors.has('senderEmail')" >{{ errors.first("senderEmail") }}</span>
            <span class="answer" v-if="pages.productName && pages.productName != 'Default'" >{{ pages.senderEmail }}</span>
          </div>
        </vs-col>
      </vs-row>
      </div>
    </vs-card>
    <vs-card>
      <div slot="header">
        <h3 class="font-medium py-4 px-10 bg-grey-background">Content</h3>
      </div>
      <vs-form>
        <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" v-if="!pageId">
            <div class="w-full m-5">
              <label class="label text-lg font-semibold" for="TemplateName">Template Name</label>
              <vs-input id="TemplateName" class="w-full col-sm-4" data-vv-validate-on="blur" name="templateName" v-model="pages.templateName"></vs-input>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" v-if="!pageId">
            <div class="w-full m-4 mb-1">
              <label class="label text-lg font-semibold" for="templateCategory">Template Category</label>
              <vs-select autocomplete v-model="pages.templateCategory" name="templateCategory" class="w-full rounded" v-validate="'required'"
                placeholder="Template category" @change="chooseTemplateCategory($event, item)">
                <vs-select-item :key="index" :value="item" :text="item.templateCategory" v-for="(item, index) in categoryList"></vs-select-item>
              </vs-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full py-6 px-10">
              <label class="heading" for="SubjectLine">Subject Line </label>
              <vs-input id="SubjectLine" v-validate="'required|min:3|max:200'" class="col-sm-4 w-full" data-vv-validate-on="blur" name="subject" v-model="pages.subject"></vs-input>
              <span class="text-danger text-sm">{{errors.first("subject")}}</span>
            </div>
          </vs-col>
        </vs-row>
      </vs-form>
      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full py-6 px-10">
            <span class="heading">Available Linked Content</span>
            <div class="linkedContent mt-4">
              <ul>
                <li class="keywords" v-for="(item, key) in pages.keywords" @click="addKeyword(`{{${item}}}`, 'commsTemplateEditor')" :key="key">{{ item }}</li>
              </ul>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="editEmail w-full py-6 px-10">
            <vs-label class="heading mb-5"> Body</vs-label>
            <quillEditor class="mt-4" v-model="pages.content" ref="commsTemplateEditor" @isEditorFocused="isEditorFocused" @onEditorBlur="onEditorBlur"></quillEditor>
            <span class="text-danger text-sm">{{ errors.first("content") }}</span>
          </div>
        </vs-col>
      </vs-row>
      <div class="p-4 w-full flex justify-end items-center">
        <vs-button class="mr-8" v-round @click="revertToDefault" align="left" v-if="emailTemplate.productName && emailTemplate.productName != 'Default'">Revert To Default</vs-button>
        <vs-button class="mr-8" size="large" v-round @click="saveEmailDetails" align="left" :disabled="errors.items.length > 0">Save</vs-button>
        <u><a @click="cancel">Cancel</a></u>
      </div>
    </vs-card>
  </div>
</template>

<script>
import quillEditor from "../../../QuillEditor.vue";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      templateCategory: "template category",
      senderEmail: "sender email",
    },
  },
};

Validator.localize(dictionary);

export default {
  name: "emailViewEditDetail",
  components: {
    quillEditor,
  },
  data: () => ({
    editorIndex: "",
    counterDanger: "",
    emailTemplate: "",
    defaultEmailTemplate: "",
    pageId: "",
    pages: {
      templateName: "",
      content: "",
      subject: "",
      keywords: "",
      templateCategory: "",
      slug: "",
      senderEmail: "",
    },
    categoryList: [],
    appName: process.env.VUE_APP_NAME,
    emailDomain: process.env.VUE_APP_MAIL_DOMAIN,
    defaultEmail: "noreply@" + process.env.VUE_APP_MAIL_DOMAIN,
  }),
  methods: {
    ...mapActions("email", [
      "fetchEmailDetails",
      "updateEmailDetails",
      "saveEmailTemplate",
      "fetchTemplateCategories",
      "fetchDefaultEmailBySlug",
    ]),

    ...mapActions("sms", ["fetchSmsDetails"]),
    ...mapActions("admin", ["checkLogin"]),

    addKeyword(val, editorRef) {
      this.$refs[editorRef].insertTextAtCursor(val);
    },

    isEditorFocused(quill) {
      this.editorIndex = quill.selection.savedRange.index;
    },

    getEmailDetail(id) {
      this.fetchEmailDetails(id).then((res) => {
        if (res.data.data) {
          this.emailTemplate = res.data.data;
          this.emailTemplate.senderEmail = res.data.data.senderEmail ? res.data.data.senderEmail : this.defaultEmail;
          this.pages.content = res.data.data.content;
          this.pages.subject = res.data.data.subject;
          this.pages.templateName = res.data.data.templateName;
          this.pages.description = res.data.data.description;
          this.pages.keywords = res.data.data.keywords;
          this.pages.templateCategory = res.data.data.templateCategory;
          this.pages.senderEmail = res.data.data.senderEmail ? res.data.data.senderEmail : this.defaultEmail;
          this.pages = { ...res.data.data };
        }
      }).catch((ex)=> {
        this.showToastMessage(ex.response.data.title, ex.response.data.message);
        this.$vs.loading.close();
      });
    },

    getTemplateCategories() {
      this.fetchTemplateCategories("Email").then((res) => {
        if (res.data.data) {
          this.categoryList = res.data.data.data;
        }
      });
    },

    async chooseTemplateCategory(event, templateCategory) {
      if (!this.$route.params.id) {
        this.pages.keywords = event.keywords;
        this.pages.slug = event.slug;
      }
    },

    revertToDefault() {
      let data = {
        slug: this.emailTemplate.slug,
        productType: this.emailTemplate.productType,
      };
      this.fetchDefaultEmailBySlug(data).then((res) => {
        this.defaultEmailTemplate = res.data.data;
        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages.templateName = res.data.data.templateName;
        this.pages.description = res.data.data.description;
        this.pages.keywords = res.data.data.keywords;
        this.pages.templateCategory = res.data.data.templateCategory;
        this.pages.senderEmail = res.data.data.senderEmail ? res.data.data.senderEmail : this.defaultEmail;
      });
    },

    saveEmailDetails() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }
        let id = this.$route.params.id;
        let info = this.pages;

        if (!id) {
          this.saveEmailTemplate(info).then((res) => {
            this.$vs.notify({
              subject: "Update Page Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$router.push({ name: "email-templates" });
          });
        }

        if (id) {
          info = {
            content: this.pages.content,
            description: this.pages.description,
            senderEmail: this.pages.senderEmail,
            subject: this.pages.subject,
          };

          this.updateEmailDetails({ id, info }).then((res) => {
            this.$vs.notify({
              subject: "Update Page Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.redirectPage();
          }).catch((ex) => {
            this.$vs.notify({
              subject: "Failed",
              text: ex.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
        }
      });
    },

    auto_grow(elem) {
      let element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },

    cancel() {
      this.redirectPage();
    },

    redirectPage() {
      if (this.$route.query.planId) {
        this.$router.push({
          name: "pay-later-product",
          query: { id: this.$route.query.planId, productType: this.$route.query.productType, template: true },
        });
      } else {
        this.$router.push({ name: "email-templates" });
      }
    },

    handleContentValidation() {
      if (this.pages.content.length) {
        this.errors.remove("content");
      } else if (!this.errors.has("content")) {
        this.errors.add({
          "field": "content",
          "msg": "The body field is required"
        });
      }
    }
  },

  created() {
    this.pageId = this.$route.params.id;

    if (this.pageId) {
      this.getEmailDetail(this.pageId);
    }

    this.getTemplateCategories();
    let regex = "";

    if (this.appName === "FlipPay") {
      regex = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(flippay.com.au)$/;
    } else {
      regex = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(rellopay.co)$/;
    }

    this.$validator.extend("emailFormat", {
      getMessage: () => "Email format is invalid please include valid domain (" + this.emailDomain + ")",
      validate: (value) => value.match(regex) !== null,
    });
    this.checkAdminLogin();
  },

  watch: {
    "pages.content"(val) {
      this.handleContentValidation();
    }
  }
};
</script>
